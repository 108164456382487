<template>
  <Layout>
    <WithJobs :items-per-page="itemsPerPage" :filters="filtersByJobStatus">
      <div
        slot-scope="{
          jobs,
          areJobsLoading,
          searchJobs,
          clearJobsSearch,
          totalPages,
          totalRecords,
          page,
        }"
        class="jobs-list"
      >
        <h4>View Job</h4>
        <BasePagination
          :page="page"
          :total-pages="totalPages ? totalPages : 0"
          :number-of-records="totalRecords ? totalRecords : 0"
          :items-per-page="itemsPerPage"
          :is-loading="areJobsLoading"
          record-type="Jobs"
          @nextPage="nextPage"
          @previousPage="previousPage"
        />
        <VContainer pa-0>
          <VLayout justify-center>
            <VFlex xs12 sm8>
              <BaseSearch
                placeholder="Search Jobs by Job Name"
                :messages="
                  $route.query.s
                    ? [`Search results for : '${$route.query.s}'`]
                    : []
                "
                :value="$route.query.s"
                class="px-2"
                color="white"
                @search="searchJobs"
                @clear="clearJobsSearch"/></VFlex></VLayout
        ></VContainer>

        <transition name="fade-fast" mode="out-in" appear>
          <BaseSpinner v-if="areJobsLoading" />
          <BaseRecordsList
            v-else-if="jobs && jobs.length"
            :records="jobs"
            name-key="JOB_NAME"
            :asset-type="assetType"
          />
          <p v-else-if="$route.query.s && !jobs.length"
            >No jobs matched the search "{{ $route.query.s }}".</p
          >
          <p v-else>No jobs found for this view.</p>
        </transition>

        <BasePagination
          :page="page"
          :total-pages="totalPages ? totalPages : 0"
          :number-of-records="totalRecords ? totalRecords : 0"
          :items-per-page="itemsPerPage"
          :is-loading="areJobsLoading"
          record-type="Jobs"
          @nextPage="nextPage"
          @previousPage="previousPage"
        />
        <template v-if="$route.query.s && !jobs">
          <p>No jobs matched the search "{{ $route.query.s }}".</p>
        </template>
      </div>
    </WithJobs>
  </Layout>
</template>

<script>
import * as appConfig from '@src/app.config'
import Layout from '@layouts/main'
import WithJobs from '@dataProviders/WithJobs'
import invertStatusFilters from '@utils/invertStatusFilters'

import { HumanFields, JobStatuses, ObjectTypes } from '@constants/knack'

import { createNamespacedHelpers } from 'vuex'
const { mapState: routeState } = createNamespacedHelpers('route')

export default {
  name: 'JobsListView',
  components: { Layout, WithJobs },
  metaInfo() {
    return {
      title: `Jobs ${this.query.page > 1 ? ` - Page ${this.query.page}` : ''}`,
      meta: [{ name: 'description', content: appConfig.description }],
    }
  },
  data() {
    return {
      itemsPerPage: 15,
      JobStatuses,
      assetType: ObjectTypes.JOBS,
    }
  },
  computed: {
    ...routeState(['query']),
    filtersByJobStatus() {
      let allStatuses = this.JobStatuses

      let acceptedStatuses = Object.values(allStatuses)

      let knackKeyField = HumanFields.JOBS.JOB_STATUS
      return invertStatusFilters(JobStatuses, acceptedStatuses, knackKeyField)
    }, // filtersByJobStatus
  },
  methods: {
    nextPage() {
      let pageNum = +this.query.page ? +this.query.page + 1 : 2
      this.$router.push({
        query: { ...this.$route.query, page: pageNum },
      })
    }, // nextPage
    previousPage() {
      let pageNum = +this.query.page
      pageNum = pageNum && pageNum > 1 ? pageNum - 1 : 1
      this.$router.push({
        query: { ...this.$route.query, page: pageNum },
      })
    }, // previousPage
  },
}
</script>
