<script>
import { HumanFields } from '@constants/knack'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WithJobs',
  props: {
    itemsPerPage: {
      type: Number,
      required: false,
      default: null,
    },
    filters: {
      type: [Object, Array],
      required: false,
      default: null,
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      areJobsLoading: false,
    }
  },
  computed: {
    ...mapGetters(['getJobsResponse']),
    response() {
      return this.getJobsResponse || false
    },
    fetchQuery() {
      let fetchQuery = {
        rows_per_page: this.itemsPerPage,
        filters: {
          match: 'and',
          rules: [],
        },
      }
      if (this.$route.query.page) {
        fetchQuery.page = Number(this.$route.query.page)
      }
      if (this.filters) {
        let customRules =
          typeof this.filters.rules !== 'undefined'
            ? this.filters.rules
            : this.filters
        fetchQuery.filters.rules = [...fetchQuery.filters.rules, ...customRules]
        if (this.filters.match) {
          fetchQuery.filters.match = this.filters.match
        }
      }

      if (this.$route.query.s) {
        fetchQuery.filters.match = 'and'
        fetchQuery.filters.rules = [
          ...(this.filters.rules || []),
          {
            field: HumanFields.JOBS.JOB_NAME,
            operator: 'contains',
            value: this.$route.query.s,
          },
        ]
      }

      if (this.viewMode) {
        fetchQuery.view_mode = true
      }

      return fetchQuery
    }, // fetchQuery

    searchFilter() {
      const filters = {
        match: 'and',
        rules: [
          {
            field: HumanFields.JOBS.JOB_NUMBER__IDENTIFIER,
            operator: 'contains',
            value: this.$route.query.s,
          },
        ],
      }
      return filters
    }, // searchFilter
  },
  watch: {
    $route: {
      async handler(val) {
        this.areJobsLoading = true
        await this.fetchJobs(this.fetchQuery)
        this.areJobsLoading = false
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['fetchJobs']),
    // TODO (ES) : Make these and clear functions DRY
    // (They have no specific relation to this data provider)
    searchJobs(searchTerm) {
      let revisedQuery = { ...this.$route.query }
      // Remove pagination when doing a search
      delete revisedQuery.page
      this.$router.push({ query: { ...revisedQuery, s: searchTerm } })
    },
    clearJobsSearch() {
      let query = Object.assign({}, this.$route.query)
      delete query.s
      delete query.page
      this.$router.replace({ ...this.$router.currentRoute, query: query })
    }, // clearJobsSearch
  },
  render() {
    return this.$scopedSlots.default({
      searchJobs: this.searchJobs,
      areJobsLoading: this.areJobsLoading,
      clearJobsSearch: this.clearJobsSearch,
      // from response object
      totalPages: this.response ? this.response.total_pages : 0,
      page: this.response ? Number(this.response.current_page) : 1,
      totalRecords: this.response ? this.response.total_records : 0,
      jobs: this.response ? this.response.records : [],
    })
  },
}
</script>
