/**
 * Returns the key name of an object by a defined value.
 * @param {Array} allStatuses
 * @param {Array} acceptedStatuses
 * @return {object}
 */
export default function invertStatusFilters(
  allStatuses,
  acceptedStatuses,
  knackKeyField
) {
  // get all statuses that are NOT the ones we want to retrieve
  let inverseStatuses = Object.keys(allStatuses).filter((k) => {
    let val = allStatuses[k]
    // Return any status that ISN'T 'Ready to Work' or 'Work Scheduled'
    return !acceptedStatuses.includes(val)
  })

  // Map all those 'anti' rules as 'is not' filter rules with an 'and' match policy.
  // e.g. if we're looking for 'Ready to Work' or 'Work Scheduled' we need to
  // ask the API for every location that:
  // "is not 'Completed' and is not 'Invoiced' and is not 'Hold' etc..."
  // until we only have the ones that ARE 'Ready to Work' or 'Work Scheduled'
  let rules = inverseStatuses.map((k) => {
    return {
      field: knackKeyField,
      operator: 'is not',
      value: allStatuses[k],
    }
  })

  // Also make sure to exclude Locations with a blank status
  rules.push({
    field: knackKeyField,
    operator: 'is not blank',
  })
  return { match: 'and', rules }
}
