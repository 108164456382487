var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('WithJobs',{attrs:{"items-per-page":_vm.itemsPerPage,"filters":_vm.filtersByJobStatus},scopedSlots:_vm._u([{key:"default",fn:function({
        jobs,
        areJobsLoading,
        searchJobs,
        clearJobsSearch,
        totalPages,
        totalRecords,
        page,
      }){return _c('div',{staticClass:"jobs-list"},[_c('h4',[_vm._v("View Job")]),_c('BasePagination',{attrs:{"page":page,"total-pages":totalPages ? totalPages : 0,"number-of-records":totalRecords ? totalRecords : 0,"items-per-page":_vm.itemsPerPage,"is-loading":areJobsLoading,"record-type":"Jobs"},on:{"nextPage":_vm.nextPage,"previousPage":_vm.previousPage}}),_c('VContainer',{attrs:{"pa-0":""}},[_c('VLayout',{attrs:{"justify-center":""}},[_c('VFlex',{attrs:{"xs12":"","sm8":""}},[_c('BaseSearch',{staticClass:"px-2",attrs:{"placeholder":"Search Jobs by Job Name","messages":_vm.$route.query.s
                  ? [`Search results for : '${_vm.$route.query.s}'`]
                  : [],"value":_vm.$route.query.s,"color":"white"},on:{"search":searchJobs,"clear":clearJobsSearch}})],1)],1)],1),_c('transition',{attrs:{"name":"fade-fast","mode":"out-in","appear":""}},[(areJobsLoading)?_c('BaseSpinner'):(jobs && jobs.length)?_c('BaseRecordsList',{attrs:{"records":jobs,"name-key":"JOB_NAME","asset-type":_vm.assetType}}):(_vm.$route.query.s && !jobs.length)?_c('p',[_vm._v("No jobs matched the search \""+_vm._s(_vm.$route.query.s)+"\".")]):_c('p',[_vm._v("No jobs found for this view.")])],1),_c('BasePagination',{attrs:{"page":page,"total-pages":totalPages ? totalPages : 0,"number-of-records":totalRecords ? totalRecords : 0,"items-per-page":_vm.itemsPerPage,"is-loading":areJobsLoading,"record-type":"Jobs"},on:{"nextPage":_vm.nextPage,"previousPage":_vm.previousPage}}),(_vm.$route.query.s && !jobs)?[_c('p',[_vm._v("No jobs matched the search \""+_vm._s(_vm.$route.query.s)+"\".")])]:_vm._e()],2)}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }